<template>
    <div class="layindex">
        <img class="ball" src="@/assets/ball-logo.png" alt="">
        <img class="logoname" src="@/assets/logoname.png" alt="">
        <div class="form-wrap">
            <van-field v-model="mobile" placeholder="请输入手机号" />
            <van-field v-model="captcha" maxlength="6" center clearable placeholder="请输入短信验证码">
                <template #button>
                    <div class="button-txt" @click="getCaptcha">{{ smsTxt }}</div>
                </template>
            </van-field>
            <div class="button-wrap">
                <van-button class="btn" size="small" type="default" color="#FAB021" round :disabled="!btnDisabled"
                    @click="bindMobile">立即绑定</van-button>
            </div>
        </div>
    </div>
</template>

<script>
import { Toast } from 'cube-ui'
export default {
    name: 'BindMobile',
    created() {
    },
    data() {
        return {
            mobile: '',
            captcha: '',
            disabled: false,
            second: '',
            timer: null,
            btnFlag: false
        }
    },
    computed: {
        smsTxt() {
            return this.disabled ? `${this.second}s后重新发送` : '获取验证码';
        },
        btnDisabled() {
            return this.mobile.length == 11 && this.captcha.length == 6;
        }
    },
    methods: {
        validatePhoneNumber(phoneNumber) {
            const regExp = /^1[3456789]\d{9}$/; // 手机号格式的正则表达式
            return regExp.test(phoneNumber)
        },
        getCaptcha() {
            if (this.mobile.length == 0) {
                Toast.$create({
                    type: 'warn',
                    txt: "请填写手机号",
                    time: 2000
                }).show()
                return
            }

            if (!this.validatePhoneNumber(this.mobile)) {
                Toast.$create({
                    type: 'warn',
                    txt: "手机号格式错误",
                    time: 2000
                }).show()
                return
            }

            let that = this
            let s = 60  //倒计时间
            if (!that.timer) {
                this.$http.sendCaptcha({ mobile: this.mobile }).then(res => {
                    console.log(res, 'send')
                    if (res.code == 200) {
                        that.second = s
                        that.disabled = true
                        that.timer = setInterval(() => {
                            if (that.second > 0 && this.second <= s) {
                                that.second--
                            } else {
                                that.disabled = false
                                clearInterval(that.timer)
                                this.timer = null
                            }
                        }, 1000)
                    }
                }).catch(err => {
                    console.log(err);
                });
            }
        },
        bindMobile() {

            if (this.btnFlag) {
                Toast.$create({
                    type: 'warn',
                    txt: "请勿重复操作",
                    time: 2000
                }).show()
                return
            }


            if (this.mobile.length == 0) {
                Toast.$create({
                    type: 'warn',
                    txt: "请填写手机号",
                    time: 2000
                }).show()
                return
            }

            if (this.captcha.length == 0) {
                Toast.$create({
                    type: 'warn',
                    txt: "请填写验证码",
                    time: 2000
                }).show()
                return
            }


            if (this.captcha.length != 6) {
                Toast.$create({
                    type: 'warn',
                    txt: "请填写6位验证码",
                    time: 2000
                }).show()
                return
            }

            this.btnFlag = true;

            this.$http.bindMobile({ mobile: this.mobile, code: this.captcha }).then(res => {
                this.btnFlag = false;
                if (res.code == 200) {
                    Toast.$create({
                        type: 'correct',
                        txt: "绑定成功",
                        time: 2000,
                        onTimeout: () => {
                            this.$router.push({ path: '/botnav/index' })
                        }
                    }).show()
                }
            }).catch(err => {
                this.btnFlag = false;
                console.log(err);
            });




        }
    },
}
</script>

<style scoped lang="less">
.layindex {
    width: 100%;
    height: 100%;
    position: relative;
    background: url(../assets/indexbg.png) no-repeat center;
    background-size: cover;
    overflow: hidden;
    z-index: 9;

    .telbox {
        position: absolute;
        box-sizing: border-box;
        width: 57px;
        padding: 4px 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        right: 10px;
        top: 10px;
        z-index: 10;
        background-color: #F9A709;
        border-radius: 20px;

        .icontel {
            width: 14px;
            height: 15px;
        }

        .teltext {
            font-size: 12px;
            color: #FFFFFF;
        }
    }

    .ball {
        width: 113px;
        height: 114px;
        margin: 16px auto 0;
        display: block;
    }

    .logoname {
        width: 158px;
        height: 35px;
        margin: 16px auto 0;
        display: block;
    }

    .form-wrap {
        margin: 24px 44px 0;
        background: rgba(255, 255, 255, 0.84);
        box-shadow: inset 0px 1 3px 1px #7498EB;
        border-radius: 12px 12px 12px 12px;
        border: 1px solid #707070;
        padding: 24px 10px 40px 10px;

        ::v-deep {
            .van-cell {
                padding: 0;
                height: 38px;
                background: #FFFFFF;
                box-shadow: 0px 1 3px 1px rgba(0, 0, 0, 0.16);
                border-radius: 19px 19px 19px 19px;
                margin-bottom: 8px;

                .van-cell__value {
                    height: 100%;

                    .van-field__body {
                        height: calc(100% - 24px);
                        padding: 12px 22px 12px 17px;
                    }
                }

            }
        }

        .button-txt {
            color: #214087;
            font-size: 14px;
        }

        .button-wrap {
            margin-top: 28px;

            .btn {
                width: 100%;
            }
        }
    }

}
</style>
